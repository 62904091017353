import { Grid, MenuItem, Select, styled } from "@mui/material";
import React, { useState } from "react";
import CartHeading from "./CartHeading";
import { IoMail } from "react-icons/io5";

const states = [
    { name: "Alabama", code: "AL" },
    { name: "Alaska", code: "AK" },
    { name: "Arizona", code: "AZ" },
    { name: "Arkansas", code: "AR" },
    { name: "California", code: "CA" },
    { name: "Colorado", code: "CO" },
    { name: "Connecticut", code: "CT" },
    { name: "Delaware", code: "DE" },
    { name: "Florida", code: "FL" },
    { name: "Georgia", code: "GA" },
    { name: "Hawaii", code: "HI" },
    { name: "Idaho", code: "ID" },
    { name: "Illinois", code: "IL" },
    { name: "Indiana", code: "IN" },
    { name: "Iowa", code: "IA" },
    { name: "Kansas", code: "KS" },
    { name: "Kentucky", code: "KY" },
    { name: "Louisiana", code: "LA" },
    { name: "Maine", code: "ME" },
    { name: "Maryland", code: "MD" },
    { name: "Massachusetts", code: "MA" },
    { name: "Michigan", code: "MI" },
    { name: "Minnesota", code: "MN" },
    { name: "Mississippi", code: "MS" },
    { name: "Missouri", code: "MO" },
    { name: "Montana", code: "MT" },
    { name: "Nebraska", code: "NE" },
    { name: "Nevada", code: "NV" },
    { name: "New Hampshire", code: "NH" },
    { name: "New Jersey", code: "NJ" },
    { name: "New Mexico", code: "NM" },
    { name: "New York", code: "NY" },
    { name: "North Carolina", code: "NC" },
    { name: "North Dakota", code: "ND" },
    { name: "Ohio", code: "OH" },
    { name: "Oklahoma", code: "OK" },
    { name: "Oregon", code: "OR" },
    { name: "Pennsylvania", code: "PA" },
    { name: "Rhode Island", code: "RI" },
    { name: "South Carolina", code: "SC" },
    { name: "South Dakota", code: "SD" },
    { name: "Tennessee", code: "TN" },
    { name: "Texas", code: "TX" },
    { name: "Utah", code: "UT" },
    { name: "Vermont", code: "VT" },
    { name: "Virginia", code: "VA" },
    { name: "Washington", code: "WA" },
    { name: "West Virginia", code: "WV" },
    { name: "Wisconsin", code: "WI" },
    { name: "Wyoming", code: "WY" },
];

export default function ShippingInformation({
    handleShippingChange,
    ShippingInfoCity,
    ShippingInfoStates,
    shippingInfo,
    shippingErrors,
}) {
    const CustomSelect = styled(Select)(({ theme }) => ({
        height: "2.1rem",
        marginTop: "5px",
        "& .MuiSelect-select": {
            padding: "6px 10px",
            fontSize: "0.875rem",
        },
    }));

    const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
        fontSize: "0.875rem",
    }));

    const [phoneCode, setPhoneCode] = useState(1);

    return (
        <div style={{ marginTop: "1rem" }}>
            <Grid container spacing={1.5}>
                <Grid item xs={12}>
                    <div style={{ marginTop: "10px" }}>
                        <label className="ordersummarylabel">
                            Country
                            <span className="requiredStar">&nbsp;*</span>
                        </label>
                        <div>
                            <CustomSelect
                                value={shippingInfo?.country}
                                name="country"
                                fullWidth
                                sx={{
                                    marginTop: "5px",
                                    backgroundColor: "#ebf3f9",
                                }}
                                onChange={(e) => handleShippingChange(e)}
                            >
                                <MenuItem value={"US"}>United States</MenuItem>
                            </CustomSelect>
                        </div>
                        {shippingErrors.country && (
                            <span className="errormessage">
                                {shippingErrors.country}
                            </span>
                        )}
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div>
                        <label className="ordersummarylabel">
                            First Name
                            <span className="requiredStar">&nbsp;*</span>
                        </label>
                        <div>
                            <input
                                name="first_name"
                                value={shippingInfo?.first_name}
                                onChange={(e) => handleShippingChange(e)}
                                placeholder="Enter First Name"
                                className="ordersummaryinput"
                            />
                        </div>
                        {shippingErrors.first_name && (
                            <span className="errormessage">
                                {shippingErrors.first_name}
                            </span>
                        )}
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div>
                        <label className="ordersummarylabel">
                            Last Name
                            <span className="requiredStar">&nbsp;*</span>
                        </label>
                        <div>
                            <input
                                placeholder="Enter Last Name"
                                name="last_name"
                                value={shippingInfo?.last_name}
                                onChange={(e) => handleShippingChange(e)}
                                className="ordersummaryinput"
                            />
                        </div>
                        {shippingErrors.last_name && (
                            <span className="errormessage">
                                {shippingErrors.last_name}
                            </span>
                        )}
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div>
                        <label className="ordersummarylabel">
                            Phone
                            <span className="requiredStar">&nbsp;*</span>
                        </label>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <CustomSelect
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={phoneCode}
                                onChange={(e) => setPhoneCode(e.target.value)}
                            >
                                <CustomMenuItem value={1}>+1</CustomMenuItem>
                            </CustomSelect>
                            <input
                                placeholder="Enter Phone"
                                name="phone_number"
                                value={shippingInfo?.phone_number}
                                onChange={(e) => handleShippingChange(e)}
                                className="ordersummaryinput"
                            />
                        </div>
                        {shippingErrors.phone_number && (
                            <span className="errormessage">
                                {shippingErrors.phone_number}
                            </span>
                        )}
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div>
                        <label className="ordersummarylabel">Ext.</label>
                        <div>
                            <input
                                placeholder="Enter Ext."
                                className="ordersummaryinput"
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div>
                        <label className="ordersummarylabel">
                            Company Name
                            <span className="requiredStar">&nbsp;*</span>
                        </label>
                        <div>
                            <input
                                placeholder="Enter Company Name"
                                name="companyName"
                                value={shippingInfo?.companyName}
                                onChange={(e) => handleShippingChange(e)}
                                className="ordersummaryinput"
                            />
                        </div>
                        {shippingErrors.companyName && (
                            <span className="errormessage">
                                {shippingErrors.companyName}
                            </span>
                        )}
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div>
                        <label className="ordersummarylabel">Attention</label>
                        <div>
                            <input
                                placeholder="Enter Attention"
                                className="ordersummaryinput"
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div>
                        <label className="ordersummarylabel">
                            Address Line 1
                            <span className="requiredStar">&nbsp;*</span>
                        </label>
                        <div>
                            <input
                                placeholder="Enter Address Line 1"
                                name="address_line1"
                                value={shippingInfo?.address_line1}
                                onChange={(e) => handleShippingChange(e)}
                                className="ordersummaryinput"
                            />
                        </div>
                        {shippingErrors.address_line1 && (
                            <span className="errormessage">
                                {shippingErrors.address_line1}
                            </span>
                        )}
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div>
                        <label className="ordersummarylabel">
                            Address Line 2
                        </label>
                        <div>
                            <input
                                placeholder="Enter Address Line 2"
                                name="address_line2"
                                value={shippingInfo?.address_line2}
                                onChange={(e) => handleShippingChange(e)}
                                className="ordersummaryinput"
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div>
                        <label className="ordersummarylabel">
                            State
                            <span className="requiredStar">&nbsp;*</span>
                        </label>
                        <div>
                            <CustomSelect
                                name="state"
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200, // Set the max height for the menu
                                        },
                                    },
                                }}
                                value={shippingInfo?.state}
                                fullWidth
                                sx={{
                                    marginTop: "5px",
                                    backgroundColor: "#ebf3f9",
                                }}
                                onChange={(e) => handleShippingChange(e)}
                            >
                                <MenuItem selected disabled value={10}>
                                    Choose State
                                </MenuItem>
                                {ShippingInfoStates?.map((st) => (
                                    <MenuItem
                                        key={st.state_code}
                                        value={st.state_code}
                                    >
                                        {st.state_name}
                                    </MenuItem>
                                ))}
                            </CustomSelect>
                        </div>
                        {shippingErrors.state && (
                            <span className="errormessage">
                                {shippingErrors.state}
                            </span>
                        )}
                    </div>
                </Grid>

                <Grid item md={6}>
                    <div
                        style={{
                            display: "flex",
                            gap: "10px",
                        }}
                    >
                        <div style={{ width: "100%" }}>
                            <label className="ordersummarylabel">
                                City
                                <span className="requiredStar">&nbsp;*</span>
                            </label>
                            <div>
                                <CustomSelect
                                    name="city"
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 200, // Set the max height for the menu
                                            },
                                        },
                                    }}
                                    value={shippingInfo?.city}
                                    fullWidth
                                    sx={{
                                        marginTop: "5px",
                                        backgroundColor: "#ebf3f9",
                                    }}
                                    onChange={(e) => handleShippingChange(e)}
                                >
                                    <MenuItem selected disabled value={10}>
                                        Choose City
                                    </MenuItem>
                                    {ShippingInfoCity?.map((st) => (
                                        <MenuItem
                                            key={st.city_name}
                                            value={st.city_name}
                                        >
                                            {st.city_name}
                                        </MenuItem>
                                    ))}
                                </CustomSelect>
                            </div>
                            {shippingErrors.city && (
                                <span className="errormessage">
                                    {shippingErrors.city}
                                </span>
                            )}
                        </div>
                        <div style={{ width: "200px" }}>
                            <label className="ordersummarylabel">
                                Postal Code
                                <span className="requiredStar">&nbsp;*</span>
                            </label>
                            <div>
                                <input
                                    placeholder="Enter Postal Code"
                                    name="zip_code"
                                    value={shippingInfo?.zip_code}
                                    onChange={(e) => handleShippingChange(e)}
                                    className="ordersummaryinput"
                                />
                            </div>
                            {shippingErrors.zip_code && (
                                <span className="errormessage">
                                    {shippingErrors.zip_code}
                                </span>
                            )}
                        </div>
                    </div>
                </Grid>
                {/* <Grid item md={6}>
                                            <div>
                                                <label className="ordersummarylabel">
                                                    Address Type
                                                </label>
                                                <div>
                                                    <CustomSelect
                                                        value={age}
                                                        fullWidth
                                                        sx={{
                                                            marginTop: "5px",
                                                            backgroundColor:
                                                                "#ebf3f9",
                                                        }}
                                                        onChange={handleChange}
                                                    >
                                                        <MenuItem value="Billing Address">
                                                            Billing Address
                                                        </MenuItem>
                                                        <MenuItem value="Shipping Address">
                                                            Shipping Address
                                                        </MenuItem>
                                                    </CustomSelect>
                                                </div>
                                            </div>
                                        </Grid> */}
            </Grid>
        </div>
    );
}
