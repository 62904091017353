import { useRoutes } from "react-router-dom";
import BlankLayout from "../Layouts/BlankLayout";
import Home from "../Pages/Home";
import Login from "../Pages/Login";
import Register from "../Pages/Register";
import ForgotPassword from "../Pages/ForgotPassword";
import ProductDetails from "../Pages/ProductDetails";
import PartTracker from "../Pages/PartTracker";
import ShoppingCart from "../Pages/ShoppingCart";
import OrderSummary from "../Pages/OrderSummary";
import NotFound from "../Pages/404";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeSuccessPage from "../Pages/Success";
import StripeCancelPage from "../Pages/Cancel";
import MailVerification from "../Pages/EmailVerification";
import ResetPassword from "../Pages/ResetPassword";
import VerifyRegister from "../Pages/VerifyRegister";


const stripePromise = loadStripe("pk_test_51Ps3gFJTSzH0eoPHvJNFLwyEzeFWheOM7onttK2Bn3qSbflzkK7a6aZIEm0TGzMtLudmX5qH01dAi5TAK5Wyf98p007WGTsbRy");
function Routee() {
    const element = useRoutes([
        {
            path: "/",
            element: <BlankLayout />,
            children: [
                { path: "/", element: <Home /> },
                { path: "/login", element: <Login /> },
                { path: "/register", element: <Register /> },
                { path: "/forgot-password", element: <ForgotPassword /> },
                { path: "/part/:partId", element: <ProductDetails /> },
                { path: "/part-tracker", element: <PartTracker /> },
                { path: "/shopping-cart", element: <ShoppingCart /> },
                { path: "/success", element: <StripeSuccessPage /> },
                { path: "/cancel", element: <StripeCancelPage /> },
                { path: "/verification", element: <MailVerification /> },
                { path: "/reset-password", element: <ResetPassword /> },
                { path: "/verify-register", element: <VerifyRegister /> },
                { path: "/order-summary", element: <Elements stripe={stripePromise}><OrderSummary /></Elements>},
                { path: "*", element: <NotFound /> },
            ],
        },
    ]);

    return element;
}

export default Routee;
