import React, { useEffect, useState } from "react";
import HomeNav from "../Component/HomeNav";
import "./home.css";
import product from "../Assets/Images/Product 3.png";
import { Button, CircularProgress, Container } from "@mui/material";
import { AiFillPlusCircle } from "react-icons/ai";
import { MdModeEditOutline } from "react-icons/md";
import { IoEye } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import ProductModal from "./ProductModal";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../Constant_key";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export default function PartTracker() {
    const navigate = useNavigate();
    const [trackingData, setTrackingData] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        getAllTrackPart();
    }, []);

    const getAllTrackPart = () => {
        setLoader(true);
        const token = localStorage.getItem("token");

        axios
            .post(`${baseUrl}api/getPartTracking`, undefined, {
                headers: { Authorization: `bearer ${token}` },
            })
            .then((res) => {
                console.log(res.data);
                if (res.data?.data) {
                    setTrackingData(res.data?.data);
                    setLoader(false);
                }
            })
            .catch((err) => {
                console.log(err);
                if (err?.response?.status == 401) {
                    toast.error(err?.response?.data?.message);
                    setLoader(false);
                }
                if (err?.response?.status == 404) {
                    setTrackingData([]);
                }
            })
            .finally(() => setLoader(false));
    };

    const handleEditClick = (item) => {
        // navigate(`/part/${item?.part_number}`)
        navigate(`/part/${encodeURIComponent(item?.part_number)}`, {
            state: { quantity: item?.requested_quantity },
        });
    };

    const handleDeleteTracking = (e, item) => {
        e.stopPropagation();
        Swal.fire({
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#16ace3",
            cancelButtonColor: "#d33",
            cancelButtonText: "No",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                const value = { partNumber: item?.part_number };
                const token = localStorage.getItem("token");
                axios
                    .post(`${baseUrl}api/deletePartTracking`, value, {
                        headers: { Authorization: `bearer ${token}` },
                    })
                    .then((res) => {
                        console.log(res);
                        getAllTrackPart();
                        Swal.fire({
                            title: "Deleted!",
                            text: "Your file has been deleted.",
                            icon: "success",
                        });
                    })
                    .catch((err) => {
                        toast.error(err?.response?.data?.message);
                        getAllTrackPart();
                    });
            }
        });
    };

    return (
        <div>
            <HomeNav />

            <div style={{ display: "flex", justifyContent: "center" }}>
                <div className="mainnavbar">
                    {/* <Container> */}
                    <div style={{ overflowX: "auto" }}>
                        <table
                            style={{
                                width: "100%",
                                minWidth: "800px",
                                borderCollapse: "separate",
                                borderSpacing: "0 15px",
                            }}
                        >
                            <thead>
                                <tr>
                                    {/* <th className="parttrackertableheading">
                                        Image
                                    </th> */}
                                    <th className="parttrackertableheading">
                                        Part Number
                                    </th>
                                    <th className="parttrackertableheading">
                                        Manufacturer
                                    </th>
                                    <th className="parttrackertableheading">
                                        Franchise Price/Pc
                                    </th>
                                    <th className="parttrackertableheading">
                                        Discounted Franchise Price/Pc
                                    </th>
                                    <th className="parttrackertableheading">
                                        Requested Quantity
                                    </th>
                                    <th className="parttrackertableheading">
                                        Your Wish Price
                                    </th>
                                    <th style={{ textAlign: "right" }}>
                                        <div>
                                            <Button
                                                sx={{ fontSize: "11px" }}
                                                variant="outlined"
                                                onClick={() => navigate("/")}
                                            >
                                                <AiFillPlusCircle
                                                    fontSize={16}
                                                    style={{
                                                        marginRight: "10px",
                                                        color: "black",
                                                    }}
                                                />
                                                TRACK MORE PART
                                            </Button>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {loader ? (
                                    <tr>
                                        <td
                                            style={{ textAlign: "center" }}
                                            colSpan={7}
                                        >
                                            <CircularProgress />
                                        </td>
                                    </tr>
                                ) : trackingData.length > 0 ? (
                                    trackingData.map((item) => (
                                        <tr
                                            key={item?.id}
                                            className="cursorpointer"
                                            onClick={() =>
                                                handleEditClick(item)
                                            }
                                        >
                                            <td
                                                style={{
                                                    borderTopLeftRadius: "8px",
                                                    borderBottomLeftRadius:
                                                        "8px",
                                                    borderLeft:
                                                        "1px solid #1f80c0",
                                                }}
                                                className="parttrackercellStyle"
                                            >
                                                {item?.part_number}
                                            </td>
                                            <td className="parttrackercellStyle">
                                                {item?.manufacture_name}
                                            </td>
                                            <td className="parttrackercellStyle">
                                                ${item?.franchise_pricing}
                                            </td>
                                            <td className="parttrackercellStyle">
                                                ${item?.disc_franchise_pricing}
                                            </td>
                                            <td className="parttrackercellStyle">
                                                {item?.requested_quantity}
                                            </td>
                                            <td className="parttrackercellStyle">
                                                ${item?.wish_price}
                                            </td>
                                            <td
                                                className="parttrackercellStyle"
                                                style={{
                                                    borderRight:
                                                        "1px solid #1f80c0",
                                                    borderTopRightRadius: "8px",
                                                    borderBottomRightRadius:
                                                        "8px",
                                                }}
                                            >
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "end",
                                                        alignItems: "center",
                                                        gap: "1rem",
                                                    }}
                                                >
                                                    <div
                                                        onClick={() =>
                                                            handleEditClick(
                                                                item
                                                            )
                                                        }
                                                        className="parttrackerrowicons"
                                                    >
                                                        <MdModeEditOutline
                                                            fontSize={15}
                                                        />
                                                    </div>

                                                    {/* <div className="parttrackerrowicons">
                                                            <IoEye fontSize={15} />
                                                        </div> */}

                                                    <div
                                                        onClick={(e) =>
                                                            handleDeleteTracking(
                                                                e,
                                                                item
                                                            )
                                                        }
                                                        className="parttrackerrowicons"
                                                    >
                                                        <MdDelete
                                                            fontSize={15}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td
                                            style={{ textAlign: "center" }}
                                            className="parttrackercellStyle"
                                            colSpan={7}
                                        >
                                            No parts are being tracked or wished
                                        </td>
                                    </tr>
                                )}

                                {/* <tr>
                                    <td
                                        className="parttrackercellStyle"
                                        style={{
                                            borderTopLeftRadius: "8px",
                                            borderBottomLeftRadius: "8px",
                                            borderLeft: "1px solid #1f80c0",
                                        }}
                                    >
                                        <div
                                            style={{
                                                border: "1px solid lightgrey",
                                                borderRadius: "8px",
                                                width: "70px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <img
                                                style={{ width: "65px" }}
                                                src={product}
                                            />
                                        </div>
                                    </td>
                                    <td className="parttrackercellStyle">
                                        SJ2-35853-SMT-TR
                                    </td>
                                    <td className="parttrackercellStyle">
                                        CUI Device
                                    </td>
                                    <td className="parttrackercellStyle">
                                        7,240
                                    </td>
                                    <td className="parttrackercellStyle">
                                        10 Weeks
                                    </td>
                                    <td className="parttrackercellStyle">
                                        1000
                                    </td>
                                    <td
                                        className="parttrackercellStyle"
                                        style={{
                                            borderRight: "1px solid #1f80c0",
                                            borderTopRightRadius: "8px",
                                            borderBottomRightRadius: "8px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "end",
                                                alignItems: "center",
                                                gap: "1rem",
                                            }}
                                        >
                                            <div className="parttrackerrowicons">
                                                <MdModeEditOutline
                                                    fontSize={15}
                                                />
                                            </div>

                                            <div className="parttrackerrowicons">
                                                <IoEye fontSize={15} />
                                            </div>

                                            <div className="parttrackerrowicons">
                                                <MdDelete fontSize={15} />
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td
                                        className="parttrackercellStyle"
                                        style={{
                                            borderTopLeftRadius: "8px",
                                            borderBottomLeftRadius: "8px",
                                            borderLeft: "1px solid #1f80c0",
                                        }}
                                    >
                                        <div
                                            style={{
                                                border: "1px solid lightgrey",
                                                borderRadius: "8px",
                                                width: "70px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <img
                                                style={{ width: "65px" }}
                                                src={product}
                                            />
                                        </div>
                                    </td>
                                    <td className="parttrackercellStyle">
                                        SJ2-35853-SMT-TR
                                    </td>
                                    <td className="parttrackercellStyle">
                                        CUI Device
                                    </td>
                                    <td className="parttrackercellStyle">
                                        7,240
                                    </td>
                                    <td className="parttrackercellStyle">
                                        10 Weeks
                                    </td>
                                    <td className="parttrackercellStyle">
                                        1000
                                    </td>
                                    <td
                                        className="parttrackercellStyle"
                                        style={{
                                            borderRight: "1px solid #1f80c0",
                                            borderTopRightRadius: "8px",
                                            borderBottomRightRadius: "8px",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "end",
                                                alignItems: "center",
                                                gap: "1rem",
                                            }}
                                        >
                                            <div className="parttrackerrowicons">
                                                <MdModeEditOutline
                                                    fontSize={15}
                                                />
                                            </div>

                                            <div className="parttrackerrowicons">
                                                <IoEye fontSize={15} />
                                            </div>

                                            <div className="parttrackerrowicons">
                                                <MdDelete fontSize={15} />
                                            </div>
                                        </div>
                                    </td>
                                </tr> */}
                            </tbody>
                        </table>
                    </div>
                    {/* </Container> */}
                </div>
            </div>
        </div>
    );
}
