import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { baseUrl } from "../Constant_key";
import axios from "axios";

export const searchProduct = createAsyncThunk(
    "searchProduct",
    async (value, { rejectWithValue }) => {
        try {
            const response = await axios.post(`${baseUrl}api/part-details`, value);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

const searchProductSlice = createSlice({
    name: "auth",
    initialState: {
        product: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(searchProduct.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(searchProduct.fulfilled, (state, action) => {
                state.loading = false;
                state.product = action.payload;
            })
            .addCase(searchProduct.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default searchProductSlice.reducer;
