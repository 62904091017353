import React, { useState, useEffect } from "react";
import "./home.css";
import logo from "../Assets/Images/logo.png";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "../Constant_key";
import { toast } from "react-toastify";

const MailVerification = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const email = location.state.email;
    console.log("email", email);

    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [timer, setTimer] = useState(30);

    // Start timer countdown when the component mounts or when button is clicked
    useEffect(() => {
        let interval;
        if (isButtonDisabled) {
            interval = setInterval(() => {
                setTimer((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
            }, 1000);
        }

        // If timer reaches 0, enable the resend button
        if (timer === 0) {
            setIsButtonDisabled(false);
            clearInterval(interval); // Stop the interval
        }

        return () => clearInterval(interval);
    }, [timer, isButtonDisabled]);

    // Function to handle resend email logic
    const handleResendEmail = () => {
        const value = {
            email: email,
            type: "verify-user",
        };
        axios
            .post(`${baseUrl}api/forgot-password`, value)
            .then((res) => {
                if (res.data?.status == 200) {
                    setIsButtonDisabled(true);
                    setTimer(30);
                    toast.success(res.data?.message);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, "0")}:${seconds
            .toString()
            .padStart(2, "0")}`;
    };

    return (
        <div className="verification-container">
            <img
                style={{
                    objectFit: "contain",
                    cursor: "pointer",
                    width: "350px",
                }}
                onClick={() => navigate("/")}
                src={logo}
                alt="Logo"
            />
            <div className="verification-card">
                <h2 className="verification-title">Verify Your Email</h2>
                <p className="verification-text">
                    We have sent you an email to verify your account. Please
                    check your mailbox.
                </p>
                <div>
                    <button
                        className="resend-button"
                        onClick={handleResendEmail}
                        disabled={isButtonDisabled} // Disable based on timer
                    >
                        Resend Email
                    </button>
                    <div style={{ marginTop: "10px" }}>{formatTime(timer)}</div>
                    <div style={{ marginTop: "1rem" }}>
                        <label
                            onClick={() => navigate("/login")}
                            htmlFor="tnc"
                            style={{ fontSize: "12px" }}
                            className="loginbottomoptions"
                        >
                            Back To
                            <span className="loginbottomcoloredoptions">
                                &nbsp;Login
                            </span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MailVerification;
