import React from 'react';
import { useNavigate } from 'react-router-dom';
// import { XCircle } from 'lucide-react';
import { MdCancel } from 'react-icons/md';
import './home.css';

const StripeCancelPage = () => {
  const navigate = useNavigate();

  return (
    <div className="cancel-page">
      <div className="cancel-card">
        <MdCancel className="cancel-icon" />
        <h1 className="cancel-title">Payment Cancelled</h1>
        <p className="cancel-message">
          Your payment process has been cancelled. No charges were made to your account.
        </p>
        <div className="button-group">
          <button 
            onClick={() => navigate('/order-summary')} 
            className="retry-button"
          >
            Try Again
          </button>
          <button 
            onClick={() => navigate('/')} 
            className="home-button"
          >
            Return to Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default StripeCancelPage;
