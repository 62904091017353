import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdCheckCircle } from 'react-icons/md';
import "./home.css";

const StripeSuccessPage = () => {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const navigate = useNavigate();

    //   useEffect(() => {
    //     const checkPaymentStatus = async () => {
    //       try {
    //         const response = await fetch('/api/check-payment-status');
    //         const data = await response.json();

    //         if (data.paid) {
    //           setIsAuthorized(true);
    //         } else {
    //           navigate('/');
    //         }
    //       } catch (error) {
    //         console.error('Error checking payment status:', error);
    //         navigate('/');
    //       }
    //     };

    //     checkPaymentStatus();
    //   }, [navigate]);

    //   if (!isAuthorized) {
    //     return null;
    //   }

    return (
        <div className="success-page">
            <div className="success-card">
                <MdCheckCircle className="success-icon" />
                <h1 className="success-title">Payment Successful!</h1>
                <p className="success-message">
                    Thank you for your purchase. We've sent you an email with
                    all the details.
                </p>
                <button onClick={() => navigate("/")} className="return-button">
                    Return to Home
                </button>
            </div>
        </div>
    );
};

export default StripeSuccessPage;
