import React, { useEffect, useState } from "react";
import { Button, Checkbox, Typography } from "@mui/material";
import { TextField, InputAdornment } from "@mui/material";
import { styled } from "@mui/system";
import { IoSearch } from "react-icons/io5"; // Or any other icon you prefer
import { IoIosLock } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import element from "../Assets/Images/element.png";
import "./home.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../Store/authSlice";
import axios from "axios";
import { baseUrl } from "../Constant_key";
import { toast } from "react-toastify";
import LoginContent from "../Component/LoginContent";

export default function Login() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const selector = useSelector((state) => state?.auth);
    const { isLoading, error, user } = useSelector((state) => state.auth);

    useEffect(() => {
        if (user != null) {
            localStorage.setItem("userData", JSON.stringify(user?.user));
            localStorage.setItem("isLogin", true);
            localStorage.setItem("token", user?.token);
            navigate("/");
        }
    }, [user, navigate]);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});

    const validateForm = () => {
        let tempErrors = {};
        tempErrors.username = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(username)
            ? ""
            : "Enter a valid email address";
        tempErrors.password =
            /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})/.test(password)
                ? ""
                : "Password: 10+ chars, 1 capital, 1 number, 1 symbol";
        setErrors(tempErrors);
        return Object.values(tempErrors).every((x) => x === "");
    };

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            const value = { email: username, password: password };
            try {
                await dispatch(login(value)).unwrap();
                // localStorage.setItem("isLogin", true);
            } catch (err) {
                toast.error(err.message || "Login failed. Please try again.");
            }
        } else {
            console.log("Form is invalid");
        }
    };

    return (
        <>
            <div>
                <div
                    style={{
                        display: "flex",
                        position: "absolute",
                        top: "50%",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <img className="loginelementimage" src={element} />
                    <img
                        className="loginelementimage"
                        style={{ transform: "rotate(180deg)" }}
                        src={element}
                    />
                </div>
            </div>

            <LoginContent
                handleLoginSubmit={handleLoginSubmit}
                isLoading={isLoading}
                errors={errors}
                setErrors={setErrors}
                username={username}
                setUsername={setUsername}
                setPassword={setPassword}
                password={password}
            />
        </>
    );
}
