import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import logo from "../Assets/Images/logo.png";
import { Button, Typography } from "@mui/material";
import axios from "axios";
import { baseUrl } from "../Constant_key";
import "./home.css";
import Loader from "../Component/Loader";

export default function VerifyRegister() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token"); // extract token from the URL
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        if (!token) {
            toast.error("Invalid or expired session.");
            navigate("/login");
            return false;
        }
        axios
            .post(`${baseUrl}api/verify-email`, { token })
            .then((res) => {
                if (res.data.status == 200) {
                    toast.success(res.data.message);
                    setLoader(false)
                    navigate('/login')
                }
            })
            .catch((err) => {
                if (err.response?.data?.status == 102) {
                    toast.error(err.response?.data?.message);
                    navigate("/login");
                }
            });
    }, [token]);

    const handleVerify = () => {
        if (token) {
        }
    };

    return (
        <div className="verification-container">
            {/* <img
                style={{
                    objectFit: "contain",
                    cursor: "pointer",
                    width: "350px",
                }}
                src={logo}
            /> */}

            <Loader loading={loader} />

            {/* <Typography color="primary" className="registerwelcome">
                Verify
            </Typography> */}
        </div>
    );
}
