import { Avatar } from "@mui/material";
import React from "react";
import { HiMiniShoppingCart } from "react-icons/hi2";
import "../Pages/home.css";

export default function CartHeading({icon, heading, caption}) {
    return (
        <>
            <div style={{ display: "flex", alignItems: "center" }}>
                <Avatar
                    className="shoppingheadericonbg"
                    sx={{ width: 32, height: 32 }}
                >
                    {icon}
                </Avatar>

                <div style={{ marginLeft: "8px" }}>
                    <div style={{ fontWeight: 600, color: "#1F80C0" }}>
                        {heading}
                    </div>
                    <div style={{ fontSize: "12px" }}>
                        {caption}
                    </div>
                </div>
            </div>
        </>
    );
}
