import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"; // for reading URL params
import logo from "../Assets/Images/logo.png";
import {
    Button,
    CircularProgress,
    InputAdornment,
    styled,
    TextField,
    Typography,
} from "@mui/material";
import { IoIosLock } from "react-icons/io";
import axios from "axios";
import { baseUrl } from "../Constant_key";
import { toast } from "react-toastify";

const VerticalDivider = styled("div")(({ theme }) => ({
    width: 3,
    height: 20,
    margin: theme.spacing(0.5, 0.5),
    backgroundColor: theme.palette.divider,
}));

const ResetPassword = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token"); // extract token from the URL
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [errors, setErrors] = useState({});
    const [loader, setLoader] = useState(false);

    // Password validation regex: 10+ chars, 1 uppercase, 1 number, 1 symbol
    const passwordRegex =
        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{10,}$/;

    useEffect(() => {
        if (!token) {
            toast.error("Invalid or expired session.");
            navigate("/login");
        }
    }, [token]);

    const validateForm = () => {
        let tempErrors = {};
        tempErrors.newPassword =
            /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})/.test(
                newPassword
            )
                ? ""
                : "Password: 10+ chars, 1 capital, 1 number, 1 symbol";
        tempErrors.confirmPassword =
            /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})/.test(
                confirmPassword
            )
                ? ""
                : "Password: 10+ chars, 1 capital, 1 number, 1 symbol";
        setErrors(tempErrors);
        return Object.values(tempErrors).every((x) => x === "");
    };

    const handlePasswordReset = async (e) => {
        e.preventDefault();
        
        if (validateForm()) {
            setLoader(true)
            if (!passwordRegex.test(newPassword)) {
                toast.error(
                    "Password must be at least 10 characters long, contain 1 capital letter, 1 number, and 1 symbol."
                );
                return;
            }

            if (newPassword !== confirmPassword) {
                toast.error("Passwords do not match.");
                return;
            }

            const value = {
                token,
                new_password: newPassword,
            };

            axios
                .post(`${baseUrl}api/reset-password`, value)
                .then((res) => {
                    if (res.data.status === 200) {
                        toast.success("Password reset successful");
                        setLoader(false)
                        navigate("/login");
                    }
                })
                .catch((err) => {
                    if(err.response.data.status == 102) {
                        toast.error(err.response?.data?.message);
                        setLoader(false)
                    }
                    setLoader(false)
                }).finally(() => {
                    setLoader(false)
                })
        }
    };

    return (
        <div className="verification-container">
            <img
                style={{
                    objectFit: "contain",
                    cursor: "pointer",
                    width: "350px",
                }}
                src={logo}
            />

            <Typography color="primary" className="registerwelcome">
                Reset Password
            </Typography>

            <form>
                <div>
                    <TextField
                        placeholder="New Password"
                        className="custom-text-field"
                        autoComplete="off"
                        value={newPassword}
                        type="password"
                        onChange={(e) => setNewPassword(e.target.value)}
                        sx={{ marginTop: "2rem" }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IoIosLock
                                        style={{
                                            fontSize: "1.2rem",
                                            marginRight: "4px",
                                        }}
                                    />
                                    <VerticalDivider />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <p className="errormessage">{errors.newPassword}</p>
                </div>

                <TextField
                    placeholder="Confirm Password"
                    className="custom-text-field"
                    autoComplete="off"
                    value={confirmPassword}
                    type="password"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    sx={{ marginTop: "1rem" }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <IoIosLock
                                    style={{
                                        fontSize: "1.2rem",
                                        marginRight: "4px",
                                    }}
                                />
                                <VerticalDivider />
                            </InputAdornment>
                        ),
                    }}
                />
                <p className="errormessage">{errors.confirmPassword}</p>

                <div>
                    <Button
                        sx={{
                            width: "26rem",
                            marginTop: "2rem",
                            padding: "0.4rem",
                            color: "black",
                            fontSize: "0.75rem",
                        }}
                        variant="outlined"
                        onClick={handlePasswordReset}
                        disabled={loader}
                    >
                        {loader ? <CircularProgress size={24} /> : "Reset Password"}
                    </Button>
                </div>

                <div>
                    <Button
                        color="secondary"
                        sx={{
                            width: "26rem",
                            marginTop: "1rem",
                            padding: "0.4rem",
                            color: "black",
                            fontSize: "0.75rem",
                        }}
                        variant="outlined"
                        onClick={() => navigate("/login")}
                    >
                        CANCEL
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default ResetPassword;
