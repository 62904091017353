// cartSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { baseUrl } from "../Constant_key";
import { toast } from "react-toastify";

// Thunk for adding an item to the cart
export const addToCart = createAsyncThunk(
    "cart/addToCart",
    async (productData, { getState, rejectWithValue }) => {
        try {
            const state = getState();
            const token = localStorage.getItem('token');

            if (!token) {
                console.log('User not authenticated');
                
                return rejectWithValue({ message: "User not authenticated" });
            }

            const response = await axios.post(
                `${baseUrl}api/cart/items`,
                productData,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            // Assuming the API response contains the cartId
            return response.data;
        } catch (error) {
            if (error.response) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({
                message: "An unexpected error occurred. Please try again.",
            });
        }
    }
);

export const getCartItems = createAsyncThunk(
    "cart/getCartItems",
    async (navigate, { getState, rejectWithValue }) => {
        try {
            const state = getState();
            const token = localStorage.getItem("token");
            const cartItem = state.cartReducer?.items;

            if (!token) {
                return rejectWithValue({ message: "User not authenticated" });
            }

            const response = await axios.get(`${baseUrl}api/cart`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            if (error.response) {
                if (error?.response?.data?.status == 403) {
                    toast.error(error?.response?.data?.message);
                    await localStorage.removeItem("token");
                    await localStorage.removeItem("userData");
                    await localStorage.removeItem("isLogin");
                    navigate("/login");
                    return rejectWithValue(error.response.data);
                }
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({
                message: "An unexpected error occurred. Please try again.",
            });
        }
    }
);

export const deleteCartItem = createAsyncThunk(
    "cart/deleteCartItem",
    async (value, { dispatch, rejectWithValue }) => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                return rejectWithValue({ message: "User not authenticated" });
            }

            await axios.post(`${baseUrl}api/cart/items/remove`, value, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((res)=> {
                if(res.data?.status == 200) toast.success(res.data?.message)
            })

            await dispatch(getCartItems());

            return value?.cart_id; // Return the deleted item's ID
        } catch (error) {
            if (error.response) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({
                message:
                    "An unexpected error occurred while deleting the item. Please try again.",
            });
        }
    }
);

// ... (previous code remains the same)

export const updateCartItem = createAsyncThunk(
    "cart/updateCartItem",
    async ({ value, cartId, partNumber }, { dispatch, rejectWithValue }) => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                return rejectWithValue({ message: "User not authenticated" });
            }

            const response = await axios.put(
                `${baseUrl}api/cart/${cartId}/items/${partNumber}`,
                value,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            await dispatch(getCartItems());

            return response.data;
        } catch (error) {
            if (error.response) {
                return rejectWithValue(error.response.data);
            }
            return rejectWithValue({
                message:
                    "An unexpected error occurred while updating the item. Please try again.",
            });
        }
    }
);

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        items: [],
        cartId: null,
        isLoading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addToCart.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(addToCart.fulfilled, (state, action) => {
                state.isLoading = false;
                // state.items.push(action.payload);

                // Store the cartId in the state if it comes from the API
                state.cartId = action.payload.cartId;
            })
            .addCase(addToCart.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || {
                    message: "Failed to add item to cart. Please try again.",
                };
            })
            .addCase(getCartItems.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getCartItems.fulfilled, (state, action) => {
                state.isLoading = false;
                
                state.items = action.payload;
            })
            .addCase(getCartItems.rejected, (state, action) => {
                state.isLoading = false;
                console.log('action.payload', action.payload);
                if(action.payload?.status == 404) {
                    state.items = []
                }
                state.error = action.payload || {
                    message: "Failed to fetch cart items. Please try again.",
                };
            })
            // ======================== Delete cart item =========================
            .addCase(deleteCartItem.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteCartItem.fulfilled, (state, action) => {
                state.isLoading = false;
                // state.items = state.items.filter(item => item.id !== action.payload);
            })
            .addCase(deleteCartItem.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || {
                    message:
                        "Failed to delete item from cart. Please try again.",
                };
            })

            // Add cases for updateCartItem
            .addCase(updateCartItem.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(updateCartItem.fulfilled, (state, action) => {
                state.isLoading = false;
                // Update the item in the state
                // const updatedItem = action.payload;
                // const index = state.items.findIndex(item => item.part_number === updatedItem.part_number);
                // if (index !== -1) {
                //     state.items[index] = updatedItem;
                // }
            })
            .addCase(updateCartItem.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload || {
                    message: "Failed to update item in cart. Please try again.",
                };
            });
    },
});

export default cartSlice.reducer;
