import React, { useState } from "react";
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    styled,
    TextField,
    Typography,
} from "@mui/material";
import product3 from "../Assets/Images/Product 3.png";
import close from "../Assets/Images/Close.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import images from '../Assets/Images/images.png'

const CustomTextField = styled(TextField)(({ theme }) => ({
    width: "26rem",
    backgroundColor: "#F5F5F5",
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "transparent", // Remove the border
        },
        "&:hover fieldset": {
            borderColor: "transparent", // Remove the border on hover
        },
        "&.Mui-focused fieldset": {
            borderColor: "transparent", // Remove the border on focus
        },
        "& input": {
            fontSize: "0.875rem", // Adjust font size
            padding: "8px 12px", // Adjust padding to make the height smaller
        },
    },
    "& .MuiInputAdornment-root": {
        fontSize: "0.875rem", // Adjust font size of the adornment
    },
}));

export default function ProductModal({
    part,
    open,
    handleClose,
    quantity,
    setQuantity,
}) {
    const navigate = useNavigate();
    const [error, setError] = useState({ quantity: "" });
    const defaultPartImage = images;

    const validateQty = () => {
        let isValid = true;
        let newErrors = {};

        if (quantity == null || quantity === "") {
            isValid = false;
            toast.error("Quantity is required")
            newErrors.quantity = "Quantity is required";
        } else if (!Number.isInteger(Number(quantity)) || quantity <= 0) {
            isValid = false;
            toast.error("Please enter a valid quantity")
            newErrors.quantity =
                "Please enter a valid quantity (positive integer)";
        }

        setError(newErrors);
        return isValid;
    };

    const handleConfirmBtn = () => {
        if (validateQty()) {
            handleClose();
            navigate(`/part/${encodeURIComponent(part?.ManufacturerPartNumber)}`, {
                state: { quantity },
            });
        }
    };

    return (
        <>
            <Dialog
                fullWidth={true}
                maxWidth="md"
                open={open}
                onClose={handleClose}
            >
                {/* <DialogTitle
                    style={{
                        padding: "0px",
                        paddingInline: "8px",
                        textAlign: "end",
                    }}
                >
                    <img
                        src={close}
                        className="dialogClose"
                        onClick={handleClose}
                    />
                </DialogTitle> */}
                <DialogContent
                    style={{
                        padding: "0px",
                        marginTop: '1.5rem',
                        paddingInline: "35px",
                    }}
                >
                    <div
                        style={{
                            border: "1px solid lightgrey",
                            borderRadius: "5px",
                            padding: "10px",
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={4}>
                                <div className="modelProductImageParent">
                                    <img
                                        className="modelProductImage"
                                        src={part?.ImagePath == "Not available" || part?.ImagePath == "NA" ? defaultPartImage : part?.ImagePath}
                                    />
                                </div>
                            </Grid>
                            <Grid item sm={6} md={8}>
                                <Grid container>
                                    <Grid item xs={6} sm={12} md={6}>
                                        <div
                                            style={{
                                                marginTop: "10px",
                                            }}
                                        >
                                            <label className="dialoagLabels">
                                            Manufacturer Part Number
                                            </label>
                                            <p className="dialogValues">
                                                {part?.ManufacturerPartNumber}
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item xs={6} sm={12} md={6}>
                                        <div
                                            style={{
                                                marginTop: "10px",
                                            }}
                                        >
                                            <label className="dialoagLabels">
                                                Manufacturer
                                            </label>
                                            <p className="dialogValues">
                                                {part?.Manufacturer}
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item sm={6}>
                                        <div
                                            style={{
                                                marginTop: "10px",
                                            }}
                                        >
                                            <label className="dialoagLabels">
                                                Datasheet
                                            </label>
                                            <div>
                                                <a
                                                    target="_blank"
                                                    href={
                                                        part?.DataSheetUrl ==
                                                            "NA" || part?.DataSheetUrl == "" ?
                                                            undefined :
                                                        part?.DataSheetUrl
                                                    }
                                                    style={{
                                                        color:
                                                            part?.DataSheetUrl ==
                                                            "NA" || part?.DataSheetUrl == ""
                                                                ? "gray"
                                                                : "#1F80C0", // Optional: change color if 'NA'
                                                        fontWeight: 500,
                                                        textDecoration: "none",
                                                        cursor:
                                                            part?.DataSheetUrl ==
                                                            "NA" || part?.DataSheetUrl == ""
                                                                ? "not-allowed"
                                                                : "pointer", // Optional: change cursor if 'NA'
                                                    }}
                                                    className="dialogValues"
                                                >
                                                    {part?.DataSheetUrl == "NA" || part?.DataSheetUrl == ""
                                                        ? `NA`
                                                        : `${part?.ManufacturerPartNumber} Datasheet (PDF)`}
                                                </a>
                                            </div>
                                        </div>
                                    </Grid>
                                    {/* <Grid item xs={6} sm={12} md={6}>
                                        <div
                                            style={{
                                                marginTop: "10px",
                                            }}
                                        >
                                            <label className="dialoagLabels">
                                                MFG Number
                                            </label>
                                            <p className="dialogValues">
                                                {part?.ManufacturerPartNumber}
                                            </p>
                                        </div>
                                    </Grid> */}
                                    <Grid item xs={6} sm={12} md={6}>
                                        <div
                                            style={{
                                                marginTop: "10px",
                                            }}
                                        >
                                            <label className="dialoagLabels">
                                                Lifecycle
                                            </label>
                                            <p className="dialogValues">
                                                {part?.LifecycleStatus}
                                            </p>
                                        </div>
                                    </Grid>
                                    <Grid item sm={12}>
                                        <div
                                            style={{
                                                marginTop: "10px",
                                            }}
                                        >
                                            <label className="dialoagLabels">
                                                Description
                                            </label>
                                            <p className="dialogValues">
                                                {part?.partDescription}
                                            </p>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>

                    <div
                        style={{
                            textAlign: "center",
                            marginTop: "10px",
                        }}
                    >
                        <Typography
                            color="primary"
                            sx={{
                                fontSize: "23px",
                                fontWeight: 600,
                            }}
                        >
                            Is this the correct part you are looking for?
                        </Typography>
                        <Typography
                            color="secondary"
                            style={{ marginTop: "12px" }}
                        >
                            If yes, Please enter your expected quantity below.
                        </Typography>

                        <CustomTextField
                            variant="outlined"
                            placeholder="Enter Quantity"
                            value={quantity}
                            type="number"
                            onChange={(e) => setQuantity(e.target.value)}
                            autoComplete="off"
                            sx={{ marginTop: "8px" }}
                            size="small"
                        />

                        <div
                            style={{
                                display: "flex",
                                gap: "1rem",
                                marginTop: "2rem",
                                marginBottom: '10px',
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                onClick={handleClose}
                                sx={{
                                    border: "1px solid #231F20",
                                    color: "#231F20",
                                }}
                            >
                                No, Cancel
                            </Button>
                            <Button
                                className="hoverbutton"
                                onClick={() => handleConfirmBtn()}
                                sx={{
                                    backgroundColor: "#231F20",
                                    color: "white",
                                }}
                            >
                                Confirm, Show Details
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}
