import { Grid, MenuItem, Select, styled } from "@mui/material";
import React, { useState } from "react";

const states = [
    { name: "Alabama", code: "AL" },
    { name: "Alaska", code: "AK" },
    { name: "Arizona", code: "AZ" },
    { name: "Arkansas", code: "AR" },
    { name: "California", code: "CA" },
    { name: "Colorado", code: "CO" },
    { name: "Connecticut", code: "CT" },
    { name: "Delaware", code: "DE" },
    { name: "Florida", code: "FL" },
    { name: "Georgia", code: "GA" },
    { name: "Hawaii", code: "HI" },
    { name: "Idaho", code: "ID" },
    { name: "Illinois", code: "IL" },
    { name: "Indiana", code: "IN" },
    { name: "Iowa", code: "IA" },
    { name: "Kansas", code: "KS" },
    { name: "Kentucky", code: "KY" },
    { name: "Louisiana", code: "LA" },
    { name: "Maine", code: "ME" },
    { name: "Maryland", code: "MD" },
    { name: "Massachusetts", code: "MA" },
    { name: "Michigan", code: "MI" },
    { name: "Minnesota", code: "MN" },
    { name: "Mississippi", code: "MS" },
    { name: "Missouri", code: "MO" },
    { name: "Montana", code: "MT" },
    { name: "Nebraska", code: "NE" },
    { name: "Nevada", code: "NV" },
    { name: "New Hampshire", code: "NH" },
    { name: "New Jersey", code: "NJ" },
    { name: "New Mexico", code: "NM" },
    { name: "New York", code: "NY" },
    { name: "North Carolina", code: "NC" },
    { name: "North Dakota", code: "ND" },
    { name: "Ohio", code: "OH" },
    { name: "Oklahoma", code: "OK" },
    { name: "Oregon", code: "OR" },
    { name: "Pennsylvania", code: "PA" },
    { name: "Rhode Island", code: "RI" },
    { name: "South Carolina", code: "SC" },
    { name: "South Dakota", code: "SD" },
    { name: "Tennessee", code: "TN" },
    { name: "Texas", code: "TX" },
    { name: "Utah", code: "UT" },
    { name: "Vermont", code: "VT" },
    { name: "Virginia", code: "VA" },
    { name: "Washington", code: "WA" },
    { name: "West Virginia", code: "WV" },
    { name: "Wisconsin", code: "WI" },
    { name: "Wyoming", code: "WY" },
];

export default function BillingInformation({
    handleBillingChange,
    billingInfoCity,
    billingInfo,
    errors,
    billingInfoStates,
}) {
    const CustomSelect = styled(Select)(({ theme }) => ({
        height: "2.1rem",
        marginTop: "5px",
        "& .MuiSelect-select": {
            padding: "6px 10px",
            fontSize: "0.875rem",
        },
    }));

    const CustomMenuItem = styled(MenuItem)(({ theme }) => ({
        fontSize: "0.875rem",
    }));

    const [phoneCode, setPhoneCode] = useState(1);

    return (
        <>
            <Grid container spacing={1.5}>
                <Grid item xs={12}>
                    <div style={{ marginTop: "10px" }}>
                        <label className="ordersummarylabel">
                            Country
                            <span className="requiredStar">&nbsp;*</span>
                        </label>
                        <div>
                            <CustomSelect
                                value={billingInfo?.country}
                                name="country"
                                fullWidth
                                sx={{
                                    marginTop: "5px",
                                    backgroundColor: "#ebf3f9",
                                }}
                                onChange={(e) => handleBillingChange(e)}
                            >
                                <MenuItem value={"US"}>United States</MenuItem>
                            </CustomSelect>
                        </div>
                        {errors.country && (
                            <span className="errormessage">
                                {errors.country}
                            </span>
                        )}
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div>
                        <label className="ordersummarylabel">
                            First Name
                            <span className="requiredStar">&nbsp;*</span>
                        </label>
                        <div>
                            <input
                                name="first_name"
                                value={billingInfo?.first_name}
                                onChange={(e) => handleBillingChange(e)}
                                placeholder="Enter First Name"
                                className="ordersummaryinput"
                            />
                        </div>
                        {errors.first_name && (
                            <span className="errormessage">
                                {errors.first_name}
                            </span>
                        )}
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div>
                        <label className="ordersummarylabel">
                            Last Name
                            <span className="requiredStar">&nbsp;*</span>
                        </label>
                        <div>
                            <input
                                placeholder="Enter Last Name"
                                name="last_name"
                                value={billingInfo?.last_name}
                                onChange={(e) => handleBillingChange(e)}
                                className="ordersummaryinput"
                            />
                        </div>
                        {errors.last_name && (
                            <span className="errormessage">
                                {errors.last_name}
                            </span>
                        )}
                    </div>
                </Grid>
                {/* <Grid item md={6}>
                                            <div>
                                                <label className="ordersummarylabel">
                                                    Email Address
                                                    <span className="requiredStar">
                                                        &nbsp;*
                                                    </span>
                                                </label>
                                                <div>
                                                    <input
                                                        placeholder="john@summonelec.com"
                                                        name="email"
                                                        value={
                                                            billingInfo?.email
                                                        }
                                                        onChange={(e) =>
                                                            handleBillingChange(
                                                                e
                                                            )
                                                        }
                                                        className="ordersummaryinput"
                                                    />
                                                </div>
                                                {errors.email && (
                                                    <span className="errormessage">
                                                        {errors.email}
                                                    </span>
                                                )}
                                            </div>
                                        </Grid> */}

                {/* <Grid item md={6}>
                                            <div>
                                                <label className="ordersummarylabel">
                                                    Confirm Email Address
                                                </label>
                                                <div>
                                                    <input
                                                        placeholder="john@summonelec.com"
                                                        name="confirmEmail"
                                                        value={
                                                            billingInfo?.confirmEmail
                                                        }
                                                        onChange={(e) =>
                                                            handleBillingChange(
                                                                e
                                                            )
                                                        }
                                                        className="ordersummaryinput"
                                                    />
                                                </div>
                                                {errors.confirmEmail && (
                                                    <span className="errormessage">
                                                        {errors.confirmEmail}
                                                    </span>
                                                )}
                                            </div>
                                        </Grid> */}
                <Grid item md={6}>
                    <div>
                        <label className="ordersummarylabel">
                            Phone
                            <span className="requiredStar">&nbsp;*</span>
                        </label>
                        <div style={{ display: "flex", gap: "10px" }}>
                            <CustomSelect
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={phoneCode}
                                onChange={(e) => setPhoneCode(e.target.value)}
                            >
                                <CustomMenuItem value={1}>+1</CustomMenuItem>
                            </CustomSelect>
                            <input
                                placeholder="Enter Phone"
                                name="phone_number"
                                value={billingInfo?.phone_number}
                                onChange={(e) => handleBillingChange(e)}
                                className="ordersummaryinput"
                            />
                        </div>
                        {errors.phone_number && (
                            <span className="errormessage">
                                {errors.phone_number}
                            </span>
                        )}
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div>
                        <label className="ordersummarylabel">Ext.</label>
                        <div>
                            <input
                                placeholder="Enter Ext."
                                className="ordersummaryinput"
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div>
                        <label className="ordersummarylabel">
                            Company Name
                            <span className="requiredStar">&nbsp;*</span>
                        </label>
                        <div>
                            <input
                                placeholder="Enter Company Name"
                                name="companyName"
                                value={billingInfo?.companyName}
                                onChange={(e) => handleBillingChange(e)}
                                className="ordersummaryinput"
                            />
                        </div>
                        {errors.companyName && (
                            <span className="errormessage">
                                {errors.companyName}
                            </span>
                        )}
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div>
                        <label className="ordersummarylabel">Attention</label>
                        <div>
                            <input
                                placeholder="Enter Attention"
                                className="ordersummaryinput"
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div>
                        <label className="ordersummarylabel">
                            Address Line 1
                            <span className="requiredStar">&nbsp;*</span>
                        </label>
                        <div>
                            <input
                                placeholder="Enter Address Line 1"
                                name="address_line1"
                                value={billingInfo?.address_line1}
                                onChange={(e) => handleBillingChange(e)}
                                className="ordersummaryinput"
                            />
                        </div>
                        {errors.address_line1 && (
                            <span className="errormessage">
                                {errors.address_line1}
                            </span>
                        )}
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div>
                        <label className="ordersummarylabel">
                            Address Line 2
                        </label>
                        <div>
                            <input
                                placeholder="Enter Address Line 2"
                                name="address_line2"
                                value={billingInfo?.address_line2}
                                onChange={(e) => handleBillingChange(e)}
                                className="ordersummaryinput"
                            />
                        </div>
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div>
                        <label className="ordersummarylabel">
                            State
                            <span className="requiredStar">&nbsp;*</span>
                        </label>
                        <div>
                            {/* <input
                                placeholder="Enter City"
                                name="city"
                                value={billingInfo?.city}
                                onChange={(e) => handleBillingChange(e)}
                                className="ordersummaryinput"
                            /> */}

                            <CustomSelect
                                name="state"
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 200, // Set the max height for the menu
                                        },
                                    },
                                }}
                                value={billingInfo?.state}
                                fullWidth
                                sx={{
                                    marginTop: "5px",
                                    backgroundColor: "#ebf3f9",
                                }}
                                onChange={(e) => handleBillingChange(e)}
                            >
                                <MenuItem selected disabled value={10}>
                                    Choose State
                                </MenuItem>
                                {billingInfoStates?.map((st) => (
                                    <MenuItem
                                        key={st.state_code}
                                        value={st.state_code}
                                    >
                                        {st.state_name}
                                    </MenuItem>
                                ))}
                            </CustomSelect>
                        </div>
                        {errors.state && (
                            <span className="errormessage">{errors.state}</span>
                        )}
                    </div>
                </Grid>
                <Grid item md={6}>
                    <div
                        style={{
                            display: "flex",
                            gap: "10px",
                        }}
                    >
                        <div style={{ width: "100%" }}>
                            <label className="ordersummarylabel">
                                City
                                <span className="requiredStar">&nbsp;*</span>
                            </label>
                            <div>
                                <CustomSelect
                                    name="city"
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: 200, // Set the max height for the menu
                                            },
                                        },
                                    }}
                                    value={billingInfo?.city}
                                    fullWidth
                                    sx={{
                                        marginTop: "5px",
                                        backgroundColor: "#ebf3f9",
                                    }}
                                    onChange={(e) => handleBillingChange(e)}
                                >
                                    <MenuItem selected disabled value={10}>
                                        Choose City
                                    </MenuItem>
                                    {billingInfoCity?.map((st) => (
                                        <MenuItem
                                            key={st.city_name}
                                            value={st.city_name}
                                        >
                                            {st.city_name}
                                        </MenuItem>
                                    ))}
                                </CustomSelect>
                            </div>
                            {errors.city && (
                                <span className="errormessage">
                                    {errors.city}
                                </span>
                            )}
                        </div>
                        <div style={{ width: "200px" }}>
                            <label className="ordersummarylabel">
                                Postal Code
                                <span className="requiredStar">&nbsp;*</span>
                            </label>
                            <div>
                                <input
                                    placeholder="Enter Postal Code"
                                    name="zip_code"
                                    value={billingInfo?.zip_code}
                                    onChange={(e) => handleBillingChange(e)}
                                    className="ordersummaryinput"
                                />
                            </div>
                            {errors.zip_code && (
                                <span className="errormessage">
                                    {errors.zip_code}
                                </span>
                            )}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}
